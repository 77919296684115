<template>
  <div class="right-board">
    <div class="field-box">
      <el-scrollbar class="right-scrollbar">
        <!-- 创建作业 -->
        <el-form v-show="currentTab === 'field' && showField" size="small" label-width="90px" label-position="top">
          <!-- 作业主题 -->
          <!-- <el-form-item>作业主题：课堂作业</el-form-item> -->
          <!-- 名称 -->
          <el-form-item label="名称">
            <el-input placeholder="请输入名称" v-model="workname" @input="changeWorkname" />
          </el-form-item>
          <!-- 学员提交日期 -->
          <el-form-item label="学员提交日期" class="submitDate">
            <el-date-picker v-model="beginTime" type="datetime" placeholder="选择开始日期时间"
              value-format="yyyy-MM-dd-HH:mm:ss" :readonly="isreadonly">

            </el-date-picker>
            <div st>至</div>
            <el-date-picker v-model="endTime" type="datetime" placeholder="选择结束日期时间" value-format="yyyy-MM-dd-HH:mm:ss"
              :readonly="isreadonly">

            </el-date-picker>
            <div style="width:100%;font-size:13px">
              提示：作业开始时间将给所选用户发送作业提交提醒
            </div>
          </el-form-item>
          <!-- 绑定课节 -->
          <el-form-item label="请选择课节" v-if="this.$route.query.themebind==1">
            <el-select v-model="courseid" filterable placeholder="请选择">
              <el-option v-for="item in courselist" :key="item.value" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 试卷库ID -->
          <el-form-item label="请选择试卷库" v-if="this.$route.query.themetype==4">
            <el-select v-model="repository" filterable placeholder="请选择">
              <el-option v-for="item in repositories" :key="item.value" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 答题时间设置 -->
          <el-form-item label="答题时间设置" class="settime" v-if="this.$route.query.themetype!=4">
            <el-radio v-model="isSettime" label="false">不设置</el-radio>
            <el-radio v-model="isSettime" label="true">
              答题时间
              <el-input v-model="setTime" placeholder="120" @input="changeTime"></el-input>分
            </el-radio>
            <div style="font-size:13px">（学员答题超时，系统将自动交卷）</div>
          </el-form-item>
          <!-- 提交规则 -->
          <el-form-item label="提交规则" class="submitrule">
            <!-- <el-checkbox-group v-model="ruleList"> -->
            <el-checkbox v-model="edit">是否可以二次编辑</el-checkbox>
            <el-checkbox v-model="isLook">始终不可查看他人提交的内容</el-checkbox>
            <el-checkbox v-model="needStudy" style="margin-right:50px" v-if="this.$route.query.themebind==1">课程完成观看方可提交</el-checkbox>
            <el-checkbox v-model="subagain">是否可以补交</el-checkbox>
            <template v-if="subagain">
              <el-date-picker @change="subStart" v-model="subAgainStart" type="datetime" placeholder="选择补交开始时间"
                value-format="yyyy-MM-dd-HH:mm:ss">
              </el-date-picker>
              <div>至</div>
              <el-date-picker v-model="subAgainEnd" type="datetime" placeholder="选择补交结束时间" value-format="yyyy-MM-dd-HH:mm:ss">
              </el-date-picker>
            </template>
            <!-- </el-checkbox-group> -->
          </el-form-item>
          <!-- 教师是否查看到学生提交的作业 -->
          <el-form-item label="教师是否查看到学生提交的作业" v-if="this.$route.query.themetype!=4">
            <el-radio v-model="checkcommit" label="true">是</el-radio>
            <el-radio v-model="checkcommit" label="false">否</el-radio>
          </el-form-item>
          <!-- 教师是否可以进行批改 -->
          <el-form-item label="教师是否可以进行批改" v-if="this.$route.query.themetype!=4">
            <el-radio v-model="isCheck" label="true">是</el-radio>
            <el-radio v-model="isCheck" label="false">否</el-radio>
          </el-form-item>
          <!-- 学员端展示 -->
          <el-form-item label="学员端展示" v-if="this.$route.query.themetype!=4">
            <!-- <el-checkbox v-model="score">总分数</el-checkbox>
            <el-checkbox v-model="fine">优良</el-checkbox> -->
            <el-radio v-model="score" label="true">总分数</el-radio>
            <el-radio v-model="score" label="false">优良</el-radio>
          </el-form-item>
          <el-form-item v-if="activeData.__config__.componentName !== undefined" label="组件名">
            {{ activeData.__config__.componentName }}</el-form-item>
          <el-form-item v-if="activeData.__config__.label !== undefined&&this.$route.query.themetype!=4" label="题目">
            <el-input v-model="activeData.__config__.label" placeholder="请输入标题" @input="changeRenderKey" />
          </el-form-item>
          <el-form-item v-if="activeData['start-placeholder'] !== undefined" label="开始占位">
            <el-input v-model="activeData['start-placeholder']" placeholder="请输入占位提示" />
          </el-form-item>
          <el-form-item v-if="activeData['end-placeholder'] !== undefined" label="结束占位">
            <el-input v-model="activeData['end-placeholder']" placeholder="请输入占位提示" />
          </el-form-item>
          <!-- 正确答案 -->
          <el-form-item v-if="activeData.__vModel__ !== undefined&&this.$route.query.themetype!=4" label="正确答案">
            <el-input :value="setDefaultValue(activeData.__config__.defaultValue)" placeholder="请输入默认值"
              @input="onDefaultValueInput" :disabled="true" />
          </el-form-item>
          <!-- 附件题模板 -->
          <el-form-item v-if="activeData.__config__.tag=='el-upload'" label="习题模板">
            <el-button type="primary" @click="showUpload">上传<i class="el-icon-upload el-icon--right"></i></el-button>
            <br>
            <div class="work_template" v-if="activeData.__config__.wrok_template">
              <!-- 删除按钮 -->
              <div class="delbtn" @click="deltemplate(activeData)">
                <i class="el-icon-delete" style="color:#F56C6C"></i>
              </div>
              <a :href="imgurl + activeData.__config__.wrok_template" target="_blank"
                style="margin-left: 10px">{{ activeData.__config__.wrok_template_name }}</a>
            </div>
          </el-form-item>
          <!-- 分值 -->
          <el-form-item v-if="activeData.__vModel__ !== undefined&&this.$route.query.themetype!=4" label="分值">
            <el-input :value="setDefaultValue(activeData.__config__.scoreValue)" placeholder="请输入分值"
              @input="onScoreValueInput" type="number" :min="0" :max="100" />
          </el-form-item>
          <!-- 答案解析 -->
          <el-form-item v-if="activeData.__vModel__ !== undefined&&this.$route.query.themetype!=4" label="答案解析">
            <el-input :value="setDefaultValue(activeData.__config__.resolve)" placeholder="请输入解析内容"
              @input="onResolveValueInput" />
          </el-form-item>
          <!-- 能力标准 -->
          <!-- <el-form-item label="能力标准" v-if="this.$route.query.themetype!=4">
            <el-cascader :value="setDefaultValue(activeData.__config__.power)" v-model="powerValue" :options="powerlist"
              :props="{ multiple: true, label: 'title', value: 'id' }" @change="changeUsesVal" filterable></el-cascader>
          </el-form-item> -->
          <el-form-item label="能力标准" v-if="this.$route.query.themetype!=4">
            <el-cascader v-model="powerValue" :options="powerlist"
              :props="{ multiple: true, label: 'title', value: 'id' }" @change="changeUsesVal" filterable></el-cascader>
          </el-form-item>

          <el-form-item v-if="activeData.__config__.tag === 'el-checkbox-group'" label="至少应选" style="display:none">
            <el-input-number :value="activeData.min" :min="0" placeholder="至少应选"
              @input="$set(activeData, 'min', $event ? $event : undefined)" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-checkbox-group'" label="最多可选" style="display:none">
            <el-input-number :value="activeData.max" :min="0" placeholder="最多可选"
              @input="$set(activeData, 'max', $event ? $event : undefined)" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-cascader'" label="选项分隔符">
            <el-input v-model="activeData.separator" placeholder="请输入选项分隔符" />
          </el-form-item>
          <template v-if="
              ['el-checkbox-group', 'el-select'].indexOf(
                activeData.__config__.tag
              ) > -1
            ">
            <el-divider>选项</el-divider>
            <draggable :list="activeData.__slot__.options" :animation="340" group="selectItem" handle=".option-drag">
              <div v-for="(item, index) in activeData.__slot__.options" :key="index" class="select-item">
                <div class="select-line-icon option-drag">
                  <i class="el-icon-s-operation" />
                </div>
                <el-input v-model="item.label" placeholder="选项名" size="small" />
                <el-input placeholder="选项值" size="small" :value="item.value" @input="setOptionValue(item, $event)"
                  :disabled="true" style="display:none" />
                <div class="close-btn select-line-icon" @click="activeData.__slot__.options.splice(index, 1)">
                  <i class="el-icon-remove-outline" />
                </div>
              </div>
            </draggable>
            <div style="margin-left: 20px;">
              <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text"
                @click="addSelectItem">添加选项</el-button>
            </div>
            <el-divider />
          </template>
          <!-- 单选框组 -->
          <template v-if="['el-radio-group'].indexOf(activeData.__config__.tag) > -1">
            <el-divider>选项</el-divider>
            <draggable :list="activeData.__slot__.options" :animation="340" group="selectItem" handle=".option-drag">
              <div v-for="(item, index) in activeData.__slot__.options" :key="index" class="select-item">
                <div class="select-line-icon option-drag">
                  <i class="el-icon-s-operation" />
                </div>
                <el-input v-model="item.label" placeholder="选项名" size="small" />
                <el-input placeholder="选项值" size="small" :value="item.value" @input="setOptionValue(item, $event)"
                  :disabled="true" style="display:none" />
                <div class="close-btn select-line-icon" @click="activeData.__slot__.options.splice(index, 1)">
                  <i class="el-icon-remove-outline" />
                </div>
              </div>
            </draggable>
            <div style="margin-left: 20px;">
              <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text"
                @click="addSelectItem2">添加选项</el-button>
            </div>
            <el-divider />
          </template>
          <template v-if="['el-cascader'].indexOf(activeData.__config__.tag) > -1">
            <el-divider>选项</el-divider>
            <el-form-item label="数据类型">
              <el-radio-group v-model="activeData.__config__.dataType" size="small">
                <el-radio-button label="dynamic">动态数据</el-radio-button>
                <el-radio-button label="static">静态数据</el-radio-button>
              </el-radio-group>
            </el-form-item>

            <template v-if="activeData.__config__.dataType === 'dynamic'">
              <el-form-item label="接口地址">
                <el-input v-model="activeData.__config__.url" :title="activeData.__config__.url" placeholder="请输入接口地址"
                  clearable>
                  <el-select slot="prepend" v-model="activeData.__config__.method" :style="{ width: '85px' }">
                    <el-option label="get" value="get" />
                    <el-option label="post" value="post" />
                    <el-option label="put" value="put" />
                    <el-option label="delete" value="delete" />
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="数据位置">
                <el-input v-model="activeData.__config__.dataKey" placeholder="请输入标签键名" />
              </el-form-item>
              <el-form-item label="标签键名">
                <el-input v-model="activeData.props.props.label" placeholder="请输入标签键名" />
              </el-form-item>
              <el-form-item label="值键名">
                <el-input v-model="activeData.props.props.value" placeholder="请输入值键名" />
              </el-form-item>
              <el-form-item label="子级键名">
                <el-input v-model="activeData.props.props.children" placeholder="请输入子级键名" />
              </el-form-item>
            </template>
            <!-- 级联选择静态树 -->
            <el-tree v-if="activeData.__config__.dataType === 'static'" draggable :data="activeData.options"
              node-key="id" :expand-on-click-node="false" :render-content="renderContent" />
            <div v-if="activeData.__config__.dataType === 'static'" style="margin-left: 20px">
              <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text" @click="addTreeItem">
                添加父级</el-button>
            </div>
            <el-divider />
          </template>
          <el-form-item v-if="activeData.clearable !== undefined" label="能否清空">
            <el-switch v-model="activeData.clearable" />
          </el-form-item>
          <el-form-item v-if="activeData.readonly !== undefined" label="是否只读" style="display:none">
            <el-switch v-model="activeData.readonly" />
          </el-form-item>
          <el-form-item v-if="activeData.__config__.tag === 'el-select'" label="是否多选">
            <el-switch v-model="activeData.multiple" @change="multipleChange" />
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </div>
    <el-drawer title="" :visible.sync="UploadPicture" :before-close="handleClose" @close='closeDrawer'>
      <!-- 勿删 -->
      <!-- <upimg v-if="UploadPicture" ref="upimg" :upimgload="true" @closeMain="closeMain"></upimg> -->
      <ossUploader v-if="UploadPicture" isInput="true" @closeMain="closeMain"></ossUploader>
    </el-drawer>
    <treeNode-dialog :visible.sync="dialogVisible" title="添加选项" @commit="addNode" />
    <icons-dialog :visible.sync="iconsVisible" :current="activeData[currentIconModel]" @select="setIcon" />
  </div>
</template>

<script>
import { isArray } from "util";
import TreeNodeDialog from "@/views/index/TreeNodeDialog";
import { isNumberStr } from "@/utils/index";
import IconsDialog from "./IconsDialog";
import {
  inputComponents,
  selectComponents,
  layoutComponents,
} from "@/components/generator/config";
import { saveFormConf } from "@/utils/db";

const dateTimeFormat = {
  date: "yyyy-MM-dd",
  week: "yyyy 第 WW 周",
  month: "yyyy-MM",
  year: "yyyy",
  datetime: "yyyy-MM-dd HH:mm:ss",
  daterange: "yyyy-MM-dd",
  monthrange: "yyyy-MM",
  datetimerange: "yyyy-MM-dd HH:mm:ss",
};

// 使changeRenderKey在目标组件改变时可用
const needRerenderList = ["tinymce"];

export default {
  components: {
    TreeNodeDialog,
    IconsDialog,
  },
  props: ["showField", "activeData", "formConf", "workProp"],
  data() {
    return {
      //创建作业自定义表单
      workname: "", //名称
      workType: "", //作业类型默认选中
      powerlist: [], //能力标准
      powerValue: "", //能力标准默认选中
      beginTime: "", //开始时间
      endTime: "", //结束时间
      // rangeVal: "", //时间日期范围
      isSettime: "false", //是否设置时间
      setTime: "", //设置时间默认值
      // ruleList: [], //提交规则
      edit: false, //是否可以二次编辑
      isLook: false, //始终不可查看他人提交的内容
      // supportCopy: "",
      checkcommit: "true",
      isCheck: "true",
      score: "true", //学员端展示总分数||优良
      // score: "",
      fine: false,
      resolve: "", //解析
      power: [], //能力标准
      checkboxval: [
        { val: "E" },
        { val: "F" },
        { val: "G" },
        { val: "H" },
        { val: "I" },
        { val: "J" },
        { val: "K" },
        { val: "L" },
        { val: "M" },
        { val: "N" },
        { val: "O" },
        { val: "P" },
        { val: "Q" },
        { val: "R" },
        { val: "S" },
        { val: "T" },
        { val: "U" },
        { val: "V" },
        { val: "W" },
        { val: "X" },
        { val: "Y" },
        { val: "Z" },
      ],
      checkboxind: -1,
      checkboxind2: -1,
      repository:null, //试卷库ID
      repositories:[],//试卷库
      courseid:null,//课节id
      courselist:[],//课节
      imgurl: this.downloadURL,
      UploadPicture: false, //上传图片
      subagain:false, //是否可以补交
      subAgainStart:"",// 补交开始时间
      subAgainEnd:"",//补交结束时间
      needStudy:false, //是否需要看完课程才能提交
      /*
      以上是自定义的作业属性相关配置
 - - - - - - - - -- - - - - -- - - - - -- - - - - - - -- - - - - - - -- - - - - - - -- - - - - - - 
      以下是表单自带的相关属性配置
 */

      currentTab: "field",
      currentNode: null,
      dialogVisible: false,
      iconsVisible: false,
      currentIconModel: null,
      dateTypeOptions: [
        {
          label: "日(date)",
          value: "date",
        },
        {
          label: "周(week)",
          value: "week",
        },
        {
          label: "月(month)",
          value: "month",
        },
        {
          label: "年(year)",
          value: "year",
        },
        {
          label: "日期时间(datetime)",
          value: "datetime",
        },
      ],
      dateRangeTypeOptions: [
        {
          label: "日期范围(daterange)",
          value: "daterange",
        },
        {
          label: "月范围(monthrange)",
          value: "monthrange",
        },
        {
          label: "日期时间范围(datetimerange)",
          value: "datetimerange",
        },
      ],
      colorFormatOptions: [
        {
          label: "hex",
          value: "hex",
        },
        {
          label: "rgb",
          value: "rgb",
        },
        {
          label: "rgba",
          value: "rgba",
        },
        {
          label: "hsv",
          value: "hsv",
        },
        {
          label: "hsl",
          value: "hsl",
        },
      ],
      justifyOptions: [
        {
          label: "start",
          value: "start",
        },
        {
          label: "end",
          value: "end",
        },
        {
          label: "center",
          value: "center",
        },
        {
          label: "space-around",
          value: "space-around",
        },
        {
          label: "space-between",
          value: "space-between",
        },
      ],
      layoutTreeProps: {
        label(data, node) {
          const config = data.__config__;
          return data.componentName || `${config.label}: ${data.__vModel__}`;
        },
      },
    };
  },
  computed: {
    documentLink() {
      return (
        this.activeData.__config__.document ||
        "https://element.eleme.cn/#/zh-CN/component/installation"
      );
    },
    dateOptions() {
      if (
        this.activeData.type !== undefined &&
        this.activeData.__config__.tag === "el-date-picker"
      ) {
        if (this.activeData["start-placeholder"] === undefined) {
          return this.dateTypeOptions;
        }
        return this.dateRangeTypeOptions;
      }
      return [];
    },
    tagList() {
      return [
        {
          label: "输入型组件",
          options: inputComponents,
        },
        {
          label: "选择型组件",
          options: selectComponents,
        },
      ];
    },
    activeTag() {
      return this.activeData.__config__.tag;
    },
    isShowMin() {
      return ["el-input-number", "el-slider"].indexOf(this.activeTag) > -1;
    },
    isShowMax() {
      return (
        ["el-input-number", "el-slider", "el-rate"].indexOf(this.activeTag) > -1
      );
    },
    isShowStep() {
      return ["el-input-number", "el-slider"].indexOf(this.activeTag) > -1;
    },
    //学员提交日期是否只读
    isreadonly(){
      if(this.$route.path=="/edit"&&!this.$route.query.isCreate){
          let currentTime = Date.now();
          let endTime = this.endTime.split("");
          endTime.splice(10,1," ")
          // console.log('当前时间',currentTime)
          // console.log('作业结束时间',new Date(endTime.join("")).getTime());
          if(currentTime>new Date(endTime.join("")).getTime()){
            return true
          }else{
            return false
          }
      }else{
        console.log('清除习题模吧');
        this.deltemplate()
        return false
      }
     
    }
  },
  watch: {
    formConf: {
      handler(val) {
        saveFormConf(val);
      },
      deep: true,
    },
    workProp(newval, oldval) {
      console.log('监视作业属性发生变化-',newval);
      // 创建作业主题自定义表单
      this.topicType = newval.topicType;
      this.topicname = newval.topicname;
      this.subjectValue = newval.subjectValue;
      this.topicBind = newval.topicBind;
      this.courseValue = newval.courseValue;
      //创建作业自定义表单
      this.workname = newval.workname;
      this.powerValue = newval.powerValue;
      this.beginTime = newval.beginTime;
      this.endTime = newval.endTime;
      this.workType = newval.workType;
      this.isSettime = newval.isSettime;
      this.setTime = newval.setTime;
      this.edit = newval.edit;
      this.isLook = newval.isLook;
      this.checkcommit = newval.checkcommit;
      this.isCheck = newval.isCheck;
      this.score = newval.score;
      this.fine = newval.fine;
      this.subagain = newval.subagain;
      this.subAgainStart = newval.subStart;
      this.subAgainEnd = newval.subEnd;
      this.needStudy = newval.needStudy?true:false;
      this.courseid = newval.lessonId
    },
    //监视当前选中的题目用于切换对应的能力标准
    activeData(newVal){
      console.log('当前选中了--',newVal)
      if(newVal.__config__.power){
        this.powerValue = newVal.__config__.power[0].powerVal
      }
    }
  },
  methods: {
    // 删除习题模板
    deltemplate(){
      console.log('删除习题模板');
      this.activeData.__config__.wrok_template = "",
      this.activeData.__config__.wrok_template_name = ""
    },
        //选择时间对比
      compareTime(begintime, endtime) {
        let btime = begintime.split("")
        btime.splice(10, 1, " ")
        let etime = endtime.split("")
        etime.splice(10, 1, " ")
        if (new Date(etime.join("")).getTime() <= new Date(btime.join("")).getTime()) {
          return false
        } else {
          return true
        }
      },
    // 补交开始时间回调
    subStart(val){
      console.log('补交开始时间',val);
      if(!this.endTime){
         this.$message({
            message: '请选择作业结束时间',
            type: 'warning'
          });
          this.subAgainStart = ""
      }else if(!this.compareTime(this.endTime,this.subAgainStart)){
        this.$message({
            message: '补交开始时间不能早于作业结束时间',
            type: 'warning'
          });
        this.subAgainStart = ""
      }
    },
    // 补交结束时间回调
    subEnd(val){
      console.log('补交结束时间',val);
      if(!this.endTime){
         this.$message({
            message: '请选择作业结束时间',
            type: 'warning'
          });
          this.subAgainStart = ""
      }else if(!this.subAgainStart){
        this.$message({
          message:'请选择补交开始时间',
          type:'warning'
        })
        this.subAgainEnd = ""
      }else if(!this.compareTime(this.subAgainStart,this.subAgainEnd)){
        this.$message({
            message: '补交结束时间不能早于补交开始时间',
            type: 'warning'
          });
          this.subAgainEnd = ''
      }
    },
    showUpload(){
      this.UploadPicture = true
    },
      // 关闭右侧上传抽屉提示弹窗
    handleClose(done) {
          this.$confirm('确认关闭？')
          .then(_ => {
              done();
          })
          .catch(_ => {});
      },
    closeDrawer(id) {
        console.log('关闭1')
        // console.log('当前操作的题目id', id);
        // console.log('当前用户输入', this.userval);
      },
    closeMain(val) {
      console.log('activeData',this.activeData)
      console.log(val);
      this.activeData.__config__.wrok_template = val.filemd5;
      this.activeData.__config__.wrok_template_name = val.name;
      let obj = {
        wrok_template:val.filemd5,
        wrok_template_name : val.name
      }
      this.$emit('addTemplate',obj)
      },
    //填写主题名称
    changeTopicname(value) {
      this.topicname = value;
    },
    //填写创建作业名称
    changeWorkname(value) {
      this.workname = value;
    },
    //设置交卷时间
    changeTime(value) {
      console.log(value);
      this.setTime = Math.round(value);
    },
    //获取能力标准
    getPowerlist() {
      this.$Api.Form.getAllPower()
        .then((res) => {
          // console.log("获取能力标准", res);
          this.powerlist = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取试卷库
    getrepository(){
      this.$Api.Form.getRepository()
        .then(res=>{
          // console.log("作业库--",res)
          this.repositories = res.data
        })
        .catch(err=>{console.log("获取作业库失败！",err)})
    },
    //获取课程对应的课节
    getcourse(){
      if(this.$route.query.themebind==1){
        let id = this.$route.query.courseId
        this.$Api.Course.getLessonList(id)
          .then(res=>{
            // console.log("获取课节成功",res)
            this.courselist = res.data
          })
          .catch(err=>{console.log("获取课节失败")})
      }else{
        console.log("绑定非课程不获取课节")
      }
    },
    //选择能力标准
    changeUsesVal(val) {
      console.log("选择的能力标准", val);
      if (val) {
        let arr = [];
        val.map((item) => {
          let obj = {};
          obj.star = "";
          obj.power = item[item.length - 1];
          obj.powerVal = val;
          arr.push(obj);
        });
        // this.$set(this.activeData.__config__, "power", arr);
        this.$emit('addPower',arr)
      }
    },
    addReg() {
      this.activeData.__config__.regList.push({
        pattern: "",
        message: "",
      });
    },
    addSelectItem() {
      this.checkboxind += 1;
      console.log(this.checkboxind);
      this.activeData.__slot__.options.push({
        label: "",
        value: this.checkboxval[this.checkboxind].val,
      });
    },
    addSelectItem2() {
      this.checkboxind2 += 1;
      console.log(this.checkboxind2);
      this.activeData.__slot__.options.push({
        label: "",
        value: this.checkboxval[this.checkboxind2].val,
      });
    },
    addTreeItem() {
      ++this.idGlobal;
      this.dialogVisible = true;
      this.currentNode = this.activeData.options;
    },
    renderContent(h, { node, data, store }) {
      return (
        <div class="custom-tree-node">
          <span>{node.label}</span>
          <span class="node-operation">
            <i
              on-click={() => this.append(data)}
              class="el-icon-plus"
              title="添加"
            ></i>
            <i
              on-click={() => this.remove(node, data)}
              class="el-icon-delete"
              title="删除"
            ></i>
          </span>
        </div>
      );
    },
    append(data) {
      if (!data.children) {
        this.$set(data, "children", []);
      }
      this.dialogVisible = true;
      this.currentNode = data.children;
    },
    remove(node, data) {
      this.activeData.__config__.defaultValue = []; // 避免删除时报错
      const { parent } = node;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },
    addNode(data) {
      this.currentNode.push(data);
    },
    setOptionValue(item, val) {
      item.value = isNumberStr(val) ? +val : val;
    },
    setDefaultValue(val) {
      if (Array.isArray(val)) {
        return val.join(",");
      }
      // if (['string', 'number'].indexOf(typeof val) > -1) {
      //   return val
      // }
      if (typeof val === "boolean") {
        return `${val}`;
      }
      return val;
    },
    onDefaultValueInput(str) {
      if (isArray(this.activeData.__config__.defaultValue)) {
        // 数组
        this.$set(
          this.activeData.__config__,
          "defaultValue",
          str.split(",").map((val) => (isNumberStr(val) ? +val : val))
        );
      } else if (["true", "false"].indexOf(str) > -1) {
        // 布尔
        this.$set(this.activeData.__config__, "defaultValue", JSON.parse(str));
      } else {
        // 字符串和数字
        this.$set(
          this.activeData.__config__,
          "defaultValue",
          isNumberStr(str) ? +str : str
        );
      }
    },
    onScoreValueInput(str) {
      console.log(str);
      if(str>100){
        alert("分值不能大于100")
      }else if(str<0){
        alert("分值不能小于0")
      }else{
      // 字符串和数字
      this.$set(
        this.activeData.__config__,
        "scoreValue",
        isNumberStr(str) ? +str : str
      );
      }
    },
    onResolveValueInput(str) {
      // console.log(str);
      this.$set(
        this.activeData.__config__,
        "resolve",
        isNumberStr(str) ? +str : str
      );
    },
    onSwitchValueInput(val, name) {
      if (["true", "false"].indexOf(val) > -1) {
        this.$set(this.activeData, name, JSON.parse(val));
      } else {
        this.$set(this.activeData, name, isNumberStr(val) ? +val : val);
      }
    },
    setTimeValue(val, type) {
      const valueFormat = type === "week" ? dateTimeFormat.date : val;
      this.$set(this.activeData.__config__, "defaultValue", null);
      this.$set(this.activeData, "value-format", valueFormat);
      this.$set(this.activeData, "format", val);
    },
    spanChange(val) {
      this.formConf.span = val;
    },
    multipleChange(val) {
      this.$set(this.activeData.__config__, "defaultValue", val ? [] : "");
    },
    dateTypeChange(val) {
      this.setTimeValue(dateTimeFormat[val], val);
    },
    rangeChange(val) {
      this.$set(
        this.activeData.__config__,
        "defaultValue",
        val ? [this.activeData.min, this.activeData.max] : this.activeData.min
      );
    },
    rateTextChange(val) {
      if (val) this.activeData["show-score"] = false;
    },
    rateScoreChange(val) {
      if (val) this.activeData["show-text"] = false;
    },
    colorFormatChange(val) {
      this.activeData.__config__.defaultValue = null;
      this.activeData["show-alpha"] = val.indexOf("a") > -1;
      this.activeData.__config__.renderKey = +new Date(); // 更新renderKey,重新渲染该组件
    },
    openIconsDialog(model) {
      this.iconsVisible = true;
      this.currentIconModel = model;
    },
    setIcon(val) {
      this.activeData[this.currentIconModel] = val;
    },
    tagChange(tagIcon) {
      let target = inputComponents.find(
        (item) => item.__config__.tagIcon === tagIcon
      );
      if (!target)
        target = selectComponents.find(
          (item) => item.__config__.tagIcon === tagIcon
        );
      this.$emit("tag-change", target);
    },
    changeRenderKey() {
      if (needRerenderList.includes(this.activeData.__config__.tag)) {
        this.activeData.__config__.renderKey = +new Date();
      }
    },
  },
  mounted() {
    this.getPowerlist();
    this.getrepository();
    this.getcourse()
    // console.log("当前选择的题目---", this.activeData);
  },
};
</script>

<style lang="scss" scoped>
.right-board {
  width: 350px;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 3px;
  .field-box {
    position: relative;
    height: calc(100vh - 42px);
    box-sizing: border-box;
    overflow: hidden;
    .work_template{
      position: relative;
      margin-top: 10px;
        display: flex;
        align-items: center;
        background: #EBEEF5;
        // width: 50%;
        border-radius: 3px; // cursor: pointer;
        .delbtn{
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: 50%;
          border: 1px solid #F56C6C;
          position: absolute;
          top: -10px;
          right: -10px;
        }
    }
  }
  .el-scrollbar {
    height: 100%;
  }
}
.select-item {
  display: flex;
  border: 1px dashed #fff;
  box-sizing: border-box;
  & .close-btn {
    cursor: pointer;
    color: #f56c6c;
  }
  & .el-input + .el-input {
    margin-left: 4px;
  }
}
.select-item + .select-item {
  margin-top: 4px;
}
.select-item.sortable-chosen {
  border: 1px dashed #409eff;
}
.select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #777;
}
.option-drag {
  cursor: move;
}
.time-range {
  .el-date-editor {
    width: 227px;
  }
  ::v-deep .el-icon-time {
    display: none;
  }
}
.document-link {
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  top: 0;
  left: 0;
  cursor: pointer;
  background: #409eff;
  z-index: 1;
  border-radius: 0 0 6px 0;
  text-align: center;
  line-height: 26px;
  color: #fff;
  font-size: 18px;
}
.node-label {
  font-size: 14px;
}
.node-icon {
  color: #bebfc3;
}
</style>
